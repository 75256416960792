@tailwind base;
@tailwind components;
@tailwind utilities;
::-webkit-scrollbar {
  width: 12px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d8d9da;
  border-radius: 20px;
  border: 3px solid transparent;
  background-clip: content-box;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

::-webkit-scrollbar {
  width: 13px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d8d9da;
  border-radius: 20px;
  border: 3px solid transparent;
  background-clip: content-box;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
.hideBar::-webkit-scrollbar {
  display: none;
}

/* PAGINATION STYLES */

.suspense-loading-icon {
  animation-name: suspense-loading-icon;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes suspense-loading-icon {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  background-color: white;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.app-container {
  background-color: white;
  min-height: 100vh;
}

